import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Layout from "../../layout/layout";
import HttpHandlerServices from "../../../services/httpService";
import { toast } from "react-toastify";
import dots from "../../../assets/img/dots.gif";
import pizza from "../../../assets/img/makefg.png";
import "./Chat.scss";

import { w3cwebsocket as W3CWebSocket } from "websocket";
import { ModalChange } from "./modalChange/ModalChange";

const Chat = (props) => {
  const { className } = props;

  const [detail, setDetail] = useState([]);

  /* const [conversations_id, setConversations_id] = useState(null); */

  const [conversation, setConversation] = useState([]);

  const [preConversation, setPreConversation] = useState([]);

  const [message, setMessage] = useState([]);

  const [text, setText] = useState("");

  const [modal, setModal] = useState(false);

  

  const toggle = () => setModal(!modal);

  const [loader, setLoader] = useState(false);

  const [userData, setUserData] = useState({
    id: null,
    name: null,
    email: null,
    role: null,
  });

  const [pending, setPending] = useState([]);

  const [itsEmpty, setitsEmpty] = useState(false);

  const [client, setClient] = useState(null);

  //const ktbots_id = props.match.params._id;

  const ktbots_id = props.match.params.ktbos_id;

  const [showSelect, setShowSelect] = useState(false);

  //var client = new W3CWebSocket('ws://littlecaesarswss.ktbo.mx:8090');

  //const  client = new W3CWebSocket('wss://wss.littlecaesars.hubcrm.net:443');

  //const client = new W3CWebSocket('wss://echo.websocket.org');

  useEffect(() => {
    //setClient(new W3CWebSocket('ws://littlecaesarswss.ktbo.mx:8090'));

    //setClient(new W3CWebSocket('wss://wss.littlecaesars.hubcrm.net:443'));
    //setClient(new W3CWebSocket("wss://wss.littlecaesars.hubcrm.net:443"));
    setClient(new W3CWebSocket("wss://wss.littlestaging.hubcrm.net:443"));
  }, []);

  useEffect(() => {
    if (client) {
      getUserData();

      getConversation();

      handlerSocket();
    }
  }, [client]);

  const closeCoversation = () => {
    client.onclose = function () {
      toast.error(`EL CHAT SE HA DESCONECTADO`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000,
      });
    };

    setConversation([]);

    const endpoint = `/endconversation/${ktbots_id}`;

    const httpHandlerServices = new HttpHandlerServices();

    httpHandlerServices.getMethod(endpoint).then(
      async (res) => {
        const data = await res;
        if (data) {
          getConversation();
        }
      },
      async (err) => {
        const data = await err;
        getConversation();
      }
    );
  };

  const getConversation = () => {
    setPending([1]);

    const endpoint = `/${props.match.params._conversation}/${props.match.params._id}`;

    const httpHandlerServices = new HttpHandlerServices();

    httpHandlerServices.getMethod(endpoint).then(
      async (res) => {
        const data = await res;

        setDetail(data.detail);

        setPreConversation([...data.messages]);

        setPending([]);
        setShowSelect(
          data.detail.complaint_type === "otros" ? true :
          data.detail.complaint_reason === "otros" ? true :false
        )

        if (data.messages.length < 1) {
          setitsEmpty(true);
        }
        setTimeout(() => {
          updateScroll();
        }, 10);
      },
      async (error) => {
        const err = await error;
        if (err) {
          /*   setLoader(false) */
          toast.warning(
            `SE PRODUJO UN ERROR, tal vez acaba de cerrar la conversación`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 8000,
            }
          );
        }
      }
    );
  };

  const getUserData = () => {
    const endpoint = "/user";

    const httpHandlerServices = new HttpHandlerServices();

    httpHandlerServices.getMethod(endpoint).then(
      async (res) => {
        const data = await res;

        setUserData(data);
      },
      (error) => {
        toast.error(
          `ERROR AL RECUPERAR DATOS DEL USUARIO, Cierre la session y vuelva a loguearse. Si el error persiste por favor por farvor contacte a soporte help@littlecaesars.com`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,

            autoClose: 5000,
          }
        );
      }
    );
  };

  const handlerSocket = () => {
    client.onerror = function () {
      toast.error(
        `ERROR AL INICIAR EL CHAT, Por favor intente ingresar nuevamente`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 8000,
        }
      );
    };

    client.onopen = function () {
      console.log("WebSocket Client Connected");

      function subscribe() {
        if (client.readyState === client.OPEN) {
          client.send(`{"command" : "subscribe", "channel" : ${ktbots_id}}`);
        }
      }

      subscribe();
    };

    /* client.onclose = function() {
            toast.error(`EL CHAT SE HA DESCONECTADO`
                , {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 8000
            });
        }; */

    client.onmessage = function (e) {
      if (typeof e.data === "string") {
        updateScroll();

        let mesages = message;

        const res = JSON.parse(e.data);

        mesages.push(res);

        setMessage(message);

        setConversation([...mesages]);

        let pendingArray = [...pending];

        pendingArray.splice(0, 1);

        setPending(pendingArray);

        console.log("FROM SOCKET:", res);

        if (res.viewed === -1 && res.type === "user") {
          closeCoversation();
        }
      }
    };
  };

  function updateScroll() {
    var element = document.getElementById("chatybox");

    if (element) element.scrollTop = element.scrollHeight;
  }

  const sendMesg = () => {
    setPending(["new", ...pending]);

    let message = text;

    let data = JSON.stringify({
      command: "message",
      message: message,
      type: "cms",
      cms_id: userData.id,
    });

    if (client.readyState === client.OPEN) {
      console.log("ENVIANDO ABIERTO", data, ktbots_id);

      client.send(`{"command" : "subscribe", "channel" : ${ktbots_id}}`);

      client.send(data);
    } else {
      client.onopen = () => {
        console.log("ENVIANDO REABRIENDO", data, ktbots_id);

        client.send(`{"command" : "subscribe", "channel" : ${ktbots_id}}`);

        client.send(data);
      };
    }

    setText("");
  };

  // const closeEmptyCoversation = () => {

  //     const endpoint = `/emptyconversation/${ktbots_id}`;

  //     const httpHandlerServices =  new HttpHandlerServices();

  //     httpHandlerServices.getMethod(endpoint).then(
  //         async (res) => {
  //             const data = await res;
  //             if(data) {
  //                 console.log('res', res)
  //                 props.history.push(`/atencion`);
  //             }
  //         },
  //         async (err) => {
  //             const data = await err;
  //             if(data) props.history.push(`/atencion`);
  //         }
  //     )
  // }

  const getComplaintChannel = () => {
    if (!detail || detail.length <= 0) return;

    if (detail.complaint_channel === "delivery")
      return `${detail.complaint_channel}/${detail.complaint_type}`;

    return detail.complaint_channel;
  };

  const getComplaintCategory = () => {
    if (!detail || detail.length <= 0) return;

    if (detail.complaint_channel === "mostrador") {
      
      if (detail.complaint_type === "tiempo_de_entrega")
        if (
          detail.complaint_reason === "temperatura" ||
          detail.complaint_reason === "sabor" ||
          detail.complaint_reason === "presentacion"
        )
          return "Producto";

      if (
        detail.complaint_reason === "atencion_personal" ||
        detail.complaint_reason === "sucursal_sucia" ||
        detail.complaint_reason === "orden_incorrecta"
      )
        return "Servicio";

      if (
        detail.complaint_reason === "mas_5" ||
        detail.complaint_reason === "mas_5_con_fila" ||
        detail.complaint_reason === "mas_5_sin_fila" ||
        detail.complaint_reason === "mas_10" ||
        detail.complaint_reason === "mas_10_con_fila" ||
        detail.complaint_reason === "mas_10_sin_fila" ||
        detail.complaint_reason === "mas_15" ||
        detail.complaint_reason === "mas_15_con_fila" ||
        detail.complaint_reason === "mas_15_sin_fila"
      )
        return "Tiempo";
      if (
        detail.complaint_reason === "no_recibi_ticket" ||
        detail.complaint_reason === "dificultad_para_facturar"
      )
        return "Ticket y facturacion";

      if (detail.complaint_reason === "otros") return "Otros";
    }

    if (detail.complaint_channel === "pizzaportal") {
      if (
        detail.complaint_reason === "appnosirve" ||
        detail.complaint_reason === "nosucursal" ||
        detail.complaint_reason === "problemapago" ||
        detail.complaint_reason === "nopuderealizar" ||
        detail.complaint_reason === "nollegoconfirmacion" ||
        detail.complaint_reason === "nomodificapedido"
      )
        return "Problema al realizar pedido";

      if (
        detail.complaint_reason === "nofuncionaqr" ||
        detail.complaint_reason === "orden_incorrecta" ||
        detail.complaint_reason === "nosirviomaquina" ||
        detail.complaint_reason === "orden_nolista" ||
        detail.complaint_reason === "sucursal_equivocada"
      )
        return "Problema al recoger mi pedido";

      if (
        detail.complaint_reason === "temperatura" ||
        detail.complaint_reason === "sabor" ||
        detail.complaint_reason === "presentacion"
      )
        return "Producto";

      if (
        detail.complaint_reason === "atencion_personal" ||
        detail.complaint_reason === "sucursal_sucia"
      )
        return "Servicio";

      if (
        detail.complaint_reason === "no_recibi_ticket" ||
        detail.complaint_reason === "dificultad_para_facturar"
      )
        return "Ticket y facturacion";

      if (detail.complaint_reason === "otros") return "Otros";
    }

    if (detail.complaint_channel === "delivery") {
      if (detail.complaint_type === "uber") {
        if (
          detail.complaint_reason === "temperatura" ||
          detail.complaint_reason === "sabor" ||
          detail.complaint_reason === "presentacion" ||
          detail.complaint_reason === "orden_incorrecta"
        )
          return "Producto";

        if (
          detail.complaint_reason === "tiempo_40" ||
          detail.complaint_reason === "tiempo_1hr"
        )
          return "Tiempo";

        if (detail.complaint_reason === "otros") return "Otros";
        if (
          detail.complaint_reason === "no_recibi_ticket" ||
          detail.complaint_reason === "dificultad_para_facturar"
        )
          return "Ticket y facturacion";
      }

      if (detail.complaint_type === "rappi") {
        if (
          detail.complaint_reason === "temperatura" ||
          detail.complaint_reason === "sabor" ||
          detail.complaint_reason === "presentacion" ||
          detail.complaint_reason === "orden_incorrecta"
        )
          return "Producto";

        if (
          detail.complaint_reason === "tiempo_40" ||
          detail.complaint_reason === "tiempo_1hr"
        )
          return "Tiempo";

        if (detail.complaint_reason === "otros") return "Otros";

        if (
          detail.complaint_reason === "no_recibi_ticket" ||
          detail.complaint_reason === "dificultad_para_facturar"
        )
          return "Ticket y facturacion";
      }

      if (detail.complaint_type === "didi") {
        if (
          detail.complaint_reason === "temperatura" ||
          detail.complaint_reason === "sabor" ||
          detail.complaint_reason === "presentacion" ||
          detail.complaint_reason === "orden_incorrecta"
        )
          return "Producto";

        if (
          detail.complaint_reason === "tiempo_40" ||
          detail.complaint_reason === "tiempo_1hr"
        )
          return "Tiempo";

        if (detail.complaint_reason === "otros") return "Otros";

        if (
          detail.complaint_reason === "no_recibi_ticket" ||
          detail.complaint_reason === "dificultad_para_facturar"
        )
          return "Ticket y facturacion";
      }
    }

    if (detail.complaint_type.includes("_"))
      return detail.complaint_type.replaceAll("_", " ");

    return detail.complaint_type;
  };

  const getComplaintReason = () => {
    if (!detail || detail.length <= 0) return;

    if (detail.complaint_reason === "appnosirve") return "App/Web no Funciona";
    if (detail.complaint_reason === "nosucursal")
      return "No Encontré la Sucursal Deseada";
    if (detail.complaint_reason === "problemapago")
      return "Problemas con el Método de Pago";
    if (detail.complaint_reason === "nopuderealizar")
      return "No Pude Realizar mi Pedido";
    if (detail.complaint_reason === "nollegoconfirmacion")
      return "No Llegó mi Confirmación de Pedido";
    if (detail.complaint_reason === "nomodificapedido")
      return "No Pude Cancelar o Modificar el Pedido";

    if (detail.complaint_reason === "nofuncionaqr") return "No Funciona el QR";
    if (detail.complaint_reason === "orden_incorrecta")
      return "Orden Incorrecta";
    if (detail.complaint_reason === "nosirviomaquina")
      return "No Sirvió la Máquina";
    if (detail.complaint_reason === "orden_nolista")
      return "Mi Orden no Estaba Lista";
    if (detail.complaint_reason === "sucursal_equivocada")
      return "Se Asignó el Pedido a una Sucursal Equivocada";

    if (detail.complaint_reason === "temperatura") return "Temperatura";
    if (detail.complaint_reason === "sabor") return "Sabor";
    if (detail.complaint_reason === "presentacion") return "Presentación";
    if (detail.complaint_reason === "orden_incorrecta")
      return "Orden Incorrecta";

    if (detail.complaint_reason === "atencion_personal")
      return "Atención de Personal";
    if (detail.complaint_reason === "sucursal_sucia") return "Sucursal Sucia";
    if (detail.complaint_reason === "orden_incorrecta")
      return "Orden Incorrecta";

    if (detail.complaint_reason === "mas_5") return "Más de 5 minutos";
    if (detail.complaint_reason === "mas_5_sin_fila")
      return "Más de 5 minutos con Fila";
    if (detail.complaint_reason === "mas_5_con_fila")
      return "Más de 5 minutos sin Fila";
    if (detail.complaint_reason === "mas_10") return "Más de 10 minutos";
    if (detail.complaint_reason === "mas_10_con_fila")
      return "Más de 10 minutos con Fila";
    if (detail.complaint_reason === "mas_10_sin_fila")
      return "Más de 10 minutos sin Fila";
    if (detail.complaint_reason === "mas_15") return "Más de 15 minutos";
    if (detail.complaint_reason === "mas_15_con_fila")
      return "Más de 15 minutos con Fila";
    if (detail.complaint_reason === "mas_15_sin_fila")
      return "Más de 15 minutos sin Fila";
    if (detail.complaint_reason === "tiempo_40") return "Más de 40 minutos";
    if (detail.complaint_reason === "tiempo_1hr") return "Más de 1 hora";
    if (detail.complaint_reason === "no_recibi_ticket")
      return "No recibi ticket";
    if (detail.complaint_reason === "dificultad_para_facturar")
      return "Dificultad para facturar";
    if (detail.complaint_reason === "otros") return "Otro";

    return detail.complaint_reason;
  };

  return (
    <Layout>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          ¿REALMENTE DESEA CERRAR ESTA CONVERSACIÓN?
        </ModalHeader>
        <ModalBody>
          Si cierra esta conversación no podra volverla a abrir
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              closeCoversation();
              toggle();
            }}
          >
            Si, Cerrar Conversación
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Container className="chat">
        <Row className="">
          <Col xs={12}>
            <Card className="chat_box">
              <Row className="p-4">
                <Col xs={12} md={4} className="border-right text-center">
                  <h6 className="text-uppercase text-secondary emigre-bold">
                    <i className="icomoon icon-user_service mr-2"></i>Usuario
                  </h6>
                  <h6 className="text-uppercase text-dark emigre-bold">
                    {detail ? detail.name : "N/A"}
                  </h6>
                </Col>
                <Col xs={12} md={4} className="border-right text-center">
                  <h6 className="text-uppercase text-secondary emigre-bold">
                    <i className="icomoon icon-cel mr-2"></i>Teléfono
                  </h6>
                  <h6 className="text-uppercase text-dark emigre-bold">
                    {detail ? detail.phone : "N/A"}
                  </h6>
                </Col>
                <Col xs={12} md={4} className="text-center">
                  <h6 className="text-uppercase text-secondary emigre-bold">
                    <i className="icomoon icon-mail mr-2"></i>Correo Electrónico
                  </h6>
                  <h6 className="text-dark emigre-bold">
                    {detail ? detail.email : "N/A"}
                  </h6>
                </Col>
              </Row>
            </Card>
            <Card className="" color="dark">
              <Row className={ showSelect ? 'selectShow' : "p-4"}>
                <Col xs={12} md={3} className="border-right text-center">
                  <h6 className="text-uppercase text-secondary emigre-bold">
                    <i className="icomoon icon-shop mr-2"></i>Sucursal
                  </h6>
                  <h6 className="text-uppercase text-white emigre-bold">
                    {detail ? detail.store_name : "N/A"}
                  </h6>
                </Col>
                <Col xs={12} md={3} className="border-right text-center" style={showSelect ? {paddingLeft:"0", paddingRight:"0"}:{}}>
                  <h6 className="text-uppercase text-secondary emigre-bold">
                    <i className="icomoon icon-tag mr-2"></i>Canal de Compra
                  </h6>
                  {/* <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.complaint_channel : 'N/A'}</h6> */}
                  <h6 className="text-uppercase text-white emigre-bold">
                    {detail ? getComplaintChannel() : "N/A"}
                  </h6>
                  
                </Col>
                <Col xs={12} md={3} className="border-right text-center" style={showSelect ? {paddingLeft:"0", paddingRight:"0"}:{}}>
                  <h6 className="text-uppercase text-secondary emigre-bold">
                    <i className="icomoon icon-tag mr-2"></i>Categoría
                  </h6>
                  {/* <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.complaint_type : 'N/A'}</h6> */}
                  <h6 className="text-uppercase text-white emigre-bold">
                    {detail ? getComplaintCategory() : "N/A"}
                  </h6>
                  
                </Col>
                <Col xs={12} md={3} className="text-center" style={showSelect ? {paddingLeft:"0", paddingRight:"0"}:{}}>
                  <h6 className="text-uppercase text-secondary emigre-bold">
                    <i className="icomoon icon-tag mr-2"></i>Razón
                  </h6>
                  {/* <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.complaint_reason : 'N/A'}</h6> */}
                  <h6 className="text-uppercase text-white emigre-bold">
                    {detail ? getComplaintReason() : "N/A"}
                  </h6>
                </Col>
                {showSelect && detail.status_id!="Cerrada" &&(
                  <ModalChange detail={detail}/>
                )}
              </Row>
            </Card>
            {/* <Card className="" color="dark">
                            <Row className="p-4">
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-tag mr-2"></i>Categoría</h6>
                                    <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.complaint_type : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-shop mr-2"></i>Sucursal</h6>
                                    <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.store_name : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-tag mr-2"></i>RAZÓN</h6>
                                    <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.complaint_reason : 'N/A'}</h6>
                                </Col>
                            </Row>
                        </Card> */}
            <Card className="mb-3" color="grayLC">
              <Row className="p-4">
                <Col xs={12} md={4} className="border-right text-center">
                  <h6 className="text-uppercase text-dark emigre-bold">
                    <i className="icomoon icon-conversando mr-2"></i>Tipo de
                    queja
                  </h6>
                  <h6 className="text-uppercase text-textGrayLC emigre-bold">
                    {detail ? detail.hasmessage : "N/A"}
                  </h6>
                </Col>
                <Col xs={12} md={4} className="border-right text-center">
                  <h6 className="text-uppercase text-dark emigre-bold">
                    <i className="icomoon icon-edit mr-2"></i>Status
                  </h6>
                  <h6 className="text-uppercase text-textGrayLC emigre-bold">
                    {detail ? detail.status_id : "N/A"}
                  </h6>
                </Col>
                <Col xs={12} md={4} className="text-center">
                  <h6 className="text-uppercase text-dark emigre-bold">
                    <i className="icomoon icon-user_service mr-2"></i>Atendido
                    por
                  </h6>
                  <h6 className="text-uppercase text-textGrayLC emigre-bold">
                    {detail ? detail.assigned_to : "N/A"}
                  </h6>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card id="chatybox" className="p-5 chat_msn_box">
              {/* contenido de la base de datos */}
              {preConversation.map((ele, i) => (
                <Row
                  key={ele.id}
                  className={ele.viewed === 2 ? "auto-response-box" : null}
                >
                  {ele.cms_id === 0 && ele.viewed !== 2 ? (
                    <Col xs={2} md={1} className="text-left">
                      <i className="chat_icon icomoon icon-user_service"></i>
                    </Col>
                  ) : null}

                  {ele.cms_id !== 0 && ele.viewed === 2 ? (
                    <Col xs={2} md={1} className="text-left">
                      <img
                        class="auto-response-icon"
                        src={pizza}
                        alt="pizza-icon"
                      />
                    </Col>
                  ) : null}

                  {ele.image64 === "" ||
                  ele.image64 === null ||
                  ele.image64 === undefined ? (
                    <Col
                      xs={10}
                      md={11}
                      className={
                        ele.type === "user" || ele.cms_id === 0
                          ? "p-0 text-left"
                          : "p-0 text-right"
                      }
                    >
                      <p
                        className={
                          ("text-dark m-0 emigre chat-text" &&
                            ele.type === "user") ||
                          ele.cms_id === 0
                            ? "client"
                            : "operator"
                        }
                      >
                        {ele.message}
                      </p>
                      <p className="emigre text-secondary">
                        <small>{ele.created_at}</small>
                      </p>
                    </Col>
                  ) : (
                    <Col
                      xs={10}
                      md={11}
                      className={
                        ele.type === "user" || ele.cms_id === 0
                          ? "p-0 text-left"
                          : "p-0 text-right"
                      }
                    >
                      <img
                        className="imgChat"
                        src={`data:image/jpeg;base64,${ele.image64}`}
                        alt=""
                      />
                      <p className="emigre text-secondary">
                        <small>{ele.created_at}</small>
                      </p>
                    </Col>
                  )}

                  {ele.type === "user" || ele.cms_id === 0 ? null : (
                    <Col xs={2} md={1} className="text-left">
                      <i className="chat_icon icomoon icon-atc"></i>
                    </Col>
                  )}
                </Row>
              ))}

              {/* mensajes tiempo real */}

              {conversation.map((ele, i) => (
                <Row
                  key={ele.id}
                  className={
                    ele.type === "user" || ele.cms_id === 0 ? "user" : "atc"
                  }
                >
                  {ele.type === "user" || ele.cms_id === 0 ? (
                    <Col xs={2} md={1} className="text-left">
                      <i className="chat_icon icomoon icon-user_service"></i>
                    </Col>
                  ) : null}

                  {ele.image64 === "" ||
                  ele.image64 === null ||
                  ele.image64 === undefined ? (
                    <Col
                      xs={10}
                      md={11}
                      className={
                        ele.type === "user" || ele.cms_id === 0
                          ? "p-0 text-left"
                          : "p-0 text-right"
                      }
                    >
                      <p
                        className={
                          ("text-dark m-0 emigre chat-text" &&
                            ele.type === "user") ||
                          ele.cms_id === 0
                            ? "client"
                            : "operator"
                        }
                      >
                        {ele.message}
                      </p>
                      <p className="emigre text-secondary">
                        <small>{ele.created_at}</small>
                      </p>
                    </Col>
                  ) : (
                    <Col
                      xs={10}
                      md={11}
                      className={
                        ele.type === "user" || ele.cms_id === 0
                          ? "p-0 text-left"
                          : "p-0 text-right"
                      }
                    >
                      <img
                        className="imgChat"
                        src={`data:image/jpeg;base64,${ele.image64}`}
                        alt=""
                      />
                      <p className="emigre text-secondary">
                        <small>{ele.created_at}</small>
                      </p>
                    </Col>
                  )}

                  {ele.type === "user" || ele.cms_id === 0 ? null : (
                    <Col xs={2} md={1} className="text-left">
                      <i className="chat_icon icomoon icon-atc"></i>
                    </Col>
                  )}
                </Row>
              ))}
              {pending.map((ele) => (
                <div key={ele} className="dots">
                  <img src={dots} alt="dots" />
                </div>
              ))}
            </Card>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
          {
                    detail.change_comment && (
                      <h5 className="reclasifi">{detail.change_comment}</h5>
                    )
                  }
            <Card
              color="secondary"
              className="text-white text-center p-2 rounded-0"
            >
              <Row>
                <Col xs={12}>
                  {
                    // detail.status_id === 1 ?
                    detail.status_id === "Nuevas" ? (
                      <h5 className="m-0">Sin Contestar</h5>
                    ) : null
                  }
                  {
                    // detail.status_id === 2 ?
                    detail.status_id === "Contestando" ? (
                      <h5 className="m-0">Contestando</h5>
                    ) : null
                  }
                  {
                    // detail.status_id === 3 ?
                    detail.status_id === "Cerrada" ? (
                      <h5 className="m-0">Contestada</h5>
                    ) : null
                  }
                  {
                    // detail.status_id === 4 ?
                    detail.status_id === "Sin Contestar Abierta" ? (
                      <h5 className="m-0">Sin Contestar Abierta</h5>
                    ) : null
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          {
            // detail.status_id === 3 ? null :
            detail.status_id === "Cerrada" ? null : (
              <Col xs={12}>
                <Card className="text-white text-center p-4 m-0 rounded-0">
                  <Row className="mb-4">
                    <Col xs={12}>
                      <Input
                        type="text"
                        placeholder="Escribe un mensaje aquí"
                        className="border-top-0 border-left-0 border-right-0"
                        id="message"
                        name="message"
                        value={text}
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.charCode === 13 && text !== "") {
                            sendMesg();
                          }
                        }}
                      ></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      className="d-flex justify-content-end flex-column flex-md-row"
                    >
                      {/* {
                                                itsEmpty && 
                                                <Button 
                                                    className="rounded-0 px-5 mb-3 mr-md-3 text-uppercase emigre-bold order-md-1 order-2 outline-bt"
                                                    onClick={closeEmptyCoversation}
                                                    >
                                                        Cerrar queja vacía
                                                </Button>
                                            } */}
                      <Button
                        color="dark"
                        className="rounded-0 px-5 mb-3 mr-md-3 text-uppercase emigre-bold order-md-1 order-2"
                        onClick={toggle}
                        disabled={
                          detail.hasmessage === "Sin Comentario" ? true : false
                        }
                      >
                        Cerrar aclaración
                      </Button>
                      <Button
                        color="primary"
                        className="rounded-0 px-5 mb-3 text-white text-uppercase emigre-bold order-md-2 order-1"
                        onClick={sendMesg}
                        disabled={text === "" ? true : false}
                      >
                        Responder
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )
          }
        </Row>
      </Container>
    </Layout>
  );
};

export default Chat;
